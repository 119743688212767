import { Link } from "gatsby"
import React from "react"
import Layout from "../../components/layout"

import wowCollage from "../../assets/wow-collage.png"
import wowJourney from "../../assets/wow-journey.png"
import wowPayment from "../../assets/wow-payment.png"
import wowVideo from "../../assets/wow.mp4"
import wowLoader from "../../assets/loader1.gif"
import wowWine from "../../assets/wow-wine.gif"

export default function Wowclub() {
    return (
        <Layout>
            <div className="width-container">
                <div className="single-project-container">
                    <h1>WoW Club</h1>
                    <ul className="project-header">
                        <li>
                            <span className="label">Role</span>
                            <span className="role">Frontend Development</span>
                        </li>
                        <li>
                            <span className="label">Year</span>
                            <span className="role">2017</span>
                        </li>
                        <li>
                            <span className="label">Team</span>
                            <span className="role">Parallel Labs</span>
                        </li>
                    </ul>
                    <div className="single-project-content">
                        <p>
                            WOW Club is a travel club that organizes all-women
                            trips across the world. The ‘Wow’ in WOW Club is
                            short for Women on Wanderlust. They cater to an
                            audience of modern, independent women who wants to
                            explore the world. The overall goal was to put
                            across their brand of being an exclusive travel club
                            across the digital space. My role was to seamlessly
                            translate the design to code that works across all
                            platforms.
                        </p>
                        <figure>
                            <img
                                src={wowCollage}
                                alt="wow screen collage"
                                className="mb-0"
                            ></img>
                        </figure>
                        <div className="single-project-media wow__media">
                            <img
                                src={wowJourney}
                                alt="wow journey section"
                            ></img>
                        </div>
                        <div className="single-project-media">
                            <figure>
                                <img
                                    src={wowPayment}
                                    alt="wow payment section"
                                ></img>
                                <figcaption className="image-caption">
                                    Images taken from :{" "}
                                    <a
                                        href="https://www.parallellabs.io/wowclub"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {" "}
                                        Parallel Labs{" "}
                                    </a>{" "}
                                </figcaption>
                            </figure>
                        </div>
                        <p>
                            Special attention was paid to add subtle transitions
                            that would make the design feel more alive, all the
                            while keeping in mind that nothing should come in
                            the way of the flow of information. Every transition
                            was well thought through so that all of it feels
                            like a part of one cohesive flow.
                        </p>
                        <div
                            className="single-project-media wow__media"
                            id="wow-video"
                        >
                            {/* <!-- <video src="images/wow.mp4" playsinline autoplay loop muted poster="images/wow-journey.png"></video> --> */}

                            <video
                                id="banner-video"
                                playsInline=""
                                muted=""
                                controls
                                autoPlay=""
                                loop=""
                                poster={wowJourney}
                                aria-hidden
                            >
                                <source
                                    src={wowVideo}
                                    type="video/mp4"
                                ></source>
                            </video>
                        </div>
                        <div className="single-project-media wow__animations">
                            <img
                                src={wowLoader}
                                alt="example animation of a woman driving car used on wow website"
                            ></img>
                            <img
                                src={wowWine}
                                alt="example animation of wine glass clinking used on wow website"
                            ></img>
                        </div>
                    </div>
                    <Link to="/work/biblioapp" className="read-btn">
                        Next: Biblioapp &nbsp; &#187;
                    </Link>
                </div>
            </div>
        </Layout>
    )
}
